import "./styles/_reset.scss";
import "./App.scss";

import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeaderMobile from "./components/HeaderMobile/HeaderMobile";
import HeaderLanding from "./components/HeaderLanding/HeaderLanding";
import BookDetail from "./containers/book-detail/BookDetail";
import BookListAll from "./containers/book-list-all/BookListAll";
import Login from "./containers/login/Login";
import Home from "./containers/home/Home";
import HomePublic from "./containers/a-public-area/home-public/HomePublic";
import EditBook from "./containers/edit-book/EditBook";
import BookListGenre from "./containers/book-list-genre/BookListGenre";
import UiKit from "./containers/ui-kit/UIKit";
import Profile from "./containers/profile/Profile";
import AvisoLegal from "./containers/aviso-legal/AvisoLegal";
import CondicionesGenerales from "./containers/condiciones-generales/CondicionesGenerales";
import PoliticaCookies from "./containers/politica-cookies/PoliticaCookies";
import PoliticaPrivacidad from "./containers/politica-privacidad/PoliticaPrivacidad";
import TerminosCondiciones from "./containers/terminos-condiciones/TerminosCondiciones";
import NotFound from "./containers/not-found/NotFound";
import PublishBook from "./containers/publish-book/PublishBook";
import ls from "./services/localStorage";
import validateToken from "./services/validateToken";
import ProfileAuthor from "./containers/profile-author/ProfileAuthor";
import Confirmation from "./containers/confirmation/Confirmation";
import ConfirmationEmailOk from "./containers/confirmation/ConfirmationEmailOk";
import ConfirmationEmailKo from "./containers/confirmation/ConfirmationEmailKo";
import ConfirmationSale from "./containers/confirmation/ConfirmationSale";
import HeaderPublic from "./components/aPublicArea/HeaderPublic/HeaderPublic";
import HeaderPublicMobile from "./components/aPublicArea/HeaderPublicMobile/HeaderPublicMobile";
import FooterPublic from "./components/aPublicArea/FooterPublic/FooterPublic";
import FooterPublicTwo from "./components/aPublicArea/FooterPublicTwo/FooterPublicTwo";
import FooterLanding from "./components/aPublicArea/FooterLanding/FooterLanding";
import CatalogPublic from "./containers/a-public-area/Catalog/CatalogPublic";
import { useIsPublic } from "./hooks/use-is-public";
import { useIsLanding } from "./hooks/use-is-landing";
import BookListGenrePublic from "./containers/a-public-area/book-list-genre-public/BookListGenrePublic";
import ProfileAuthorPublic from "./containers/a-public-area/profile-author-public/ProfileAuthorPublic";
import WhoContainer from "./containers/a-public-area/who-container/WhoContainer";
import WhyContainer from "./containers/a-public-area/why-container/WhyContainer";
import Authors from "./containers/authors/Authors";
import AuthorsPublicContainer from "./containers/a-public-area/authors/AuthorsPublic";
import FaqsContainer from "./containers/a-public-area/faqs/Faqs";
import FaqsPrivate from "./containers/faqs-private/FaqsPrivate";
import ContactContainer from "./containers/a-public-area/contact/Contact";
import ContactPrivate from "./containers/contact-private/ContactPrivate";

import GetYourISBN from "./containers/get-your-isbn/GetYourISBN";

import Searcher from "./containers/Searcher/Searcher";
import BlogList from "./containers/blog/blog-list";
import BlogDetail from "./containers/blog/blog-detail";

import Courses from "./containers/courses/Courses";
import CourseDetail from "./containers/courses/CourseDetail";
import Events from "./containers/events/Events";
import CourseForm from "./containers/courses/CourseForm";
import EventForm from "./containers/events/EventForm";
import LegalBases from "./containers/legal-bases/LegalBases";
import Bundle from "./containers/bundle/Bundle";

import ReaderZero from "./containers/reader-zero/ReaderZero";
import Coaching from "./containers/coaching/Coaching";
import OrtotypgraphicCorrection from "./containers/ortotypographic-correction/OrtotypographicCorrection";
import StyleCorrection from "./containers/style-correction/StyleCorrection";
import DigitalLayout from "./containers/digital-layout/DigitalLayout";
import { CookiesProvider } from 'react-cookie';

/* Element to protect routes with token */
const AuthWrapper = ({ children }) => {
  const token = ls.get("token");

  if (validateToken.validate(token)) {
    return children;
  } else {
    return <Navigate to="/login"></Navigate>;
  }
};

function App() {
  const location = useLocation();
  const isPublic = useIsPublic();
  const isLanding = useIsLanding();
  const { slug } = useParams();

  return (
    <CookiesProvider>
      <ErrorBoundary>
        <div id="wrapper">
          {!isPublic &&
            location.pathname !== "/aviso-legal" &&
            location.pathname !== "/condiciones-generales" &&
            location.pathname !== "/confirmacion-email" &&
            location.pathname !== "/politica-cookies" &&
            location.pathname !== "/politica-privacidad" &&
            location.pathname !== "/terminos-condiciones" &&
            location.pathname !== "/publica-tu-obra" &&
            location.pathname !== "/consigue-tu-isbn" &&
            location.pathname.indexOf("/editar-libro") === -1 &&
            location.pathname !== "/login" ? (
            <div className="cabecera">
              <Header />
              <HeaderMobile />
            </div>
          ) : null}
          {isPublic && (
            location.pathname !== "/consigue-tu-isbn"
          ) ? (
            <div className="cabecera">
              <HeaderPublic />
              <HeaderPublicMobile />
            </div>
          ) : null}

          {isLanding ? (
            <div className="cabecera">
              <HeaderLanding />
            </div>
          ) : null}
          <div className="cuerpo">
            <main className="main">
              <Routes>
                <Route path="/" element={<HomePublic />} />
                <Route path="/descubre" element={<CatalogPublic />} />
                <Route path="/descubre/:slug" element={<CatalogPublic />} />
                <Route path="/quienes-somos" element={<WhoContainer />} />
                <Route path="/por-que-bookolicos" element={<WhyContainer />} />
                <Route path="/lista-autores" element={<AuthorsPublicContainer />} />
                <Route path="/autores" element={<Authors />} />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:slug" element={<BlogDetail />} />
                <Route path="/noticias" element={<BlogList />} />
                <Route path="/noticias/:slug" element={<BlogDetail />} />
                <Route path="/faqs" element={<FaqsContainer />} />
                <Route
                  path="/libros-por-genero/:slug"
                  element={<BookListGenrePublic />}
                />
                <Route
                  path="/perfil-autor/:slug"
                  element={<ProfileAuthorPublic />}
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/home"
                  element={
                    <AuthWrapper>
                      {" "}
                      <Home />{" "}
                    </AuthWrapper>
                  }
                />
                <Route
                  path="/book-list"
                  element={<Navigate to='/catalogo' />}
                />
                <Route
                  path="/catalogo"
                  element={
                    <AuthWrapper>
                      <BookListAll />
                    </AuthWrapper>
                  }
                />
                <Route
                  path="/book-list/:slug"
                  element={
                    <AuthWrapper>
                      <BookListAll />
                    </AuthWrapper>
                  }
                />
                <Route
                  path="/catalogo/:slug"
                  element={
                    <AuthWrapper>
                      <BookListAll />
                    </AuthWrapper>
                  }
                />
                <Route path="/book-list-genre/:slug" element={<BookListGenre />} />
                <Route path="/catalogo-por-genero/:slug" element={<BookListGenre />} />
                <Route path="/book-detail" element={<BookDetail />} />
                <Route
                  path="/profile/*"
                  element={
                    <AuthWrapper>
                      <Profile />
                    </AuthWrapper>
                  }
                />
                <Route
                  path="/publica-tu-obra"
                  element={
                    <AuthWrapper>
                      {" "}
                      <PublishBook />{" "}
                    </AuthWrapper>
                  }
                />
                <Route path="/confirmacion-venta" element={<ConfirmationSale />} />
                <Route
                  path="/confirmacion-email-ok"
                  element={<ConfirmationEmailOk />}
                />
                <Route
                  path="/confirmacion-email-ko"
                  element={<ConfirmationEmailKo />}
                />
                <Route path="/editar-libro/:bookId" element={<EditBook />} />
                <Route path="/confirmacion-email" element={<Confirmation />} />
                <Route path="/perfil-del-autor/:slug" element={<ProfileAuthor />} />
                <Route path="/contacto" element={<ContactContainer />} />
                <Route path="/contacta-con-nosotros" element={<ContactPrivate />} />
                <Route path="/buscador" element={<AuthWrapper><Searcher /></AuthWrapper>} />
                <Route path="/busqueda" element={<Searcher />} />
                <Route path="/uikit" element={<UiKit />} />
                <Route path="/aviso-legal" element={<AvisoLegal />} />
                <Route path="/condiciones-generales" element={<CondicionesGenerales />} />
                <Route path="/politica-cookies" element={<PoliticaCookies />} />
                <Route path="/preguntas-generales" element={<FaqsPrivate />} />
                <Route path="/cursos" element={<Courses />} />
                <Route path="/curso/:slug" element={<CourseDetail />} />
                <Route path="/cursos/formulario" element={<CourseForm />} />
                <Route path="/listado-cursos" element={<Courses />} />
                <Route path="/listado-cursos/:slug" element={<CourseDetail />} />
                <Route path="/listado-cursos/formulario" element={<CourseForm />} />
                <Route path="/agenda" element={<Events />} />
                <Route path="/eventos" element={<Events />} />
                <Route path="/eventos/formulario" element={<EventForm />} />
                <Route path="/bundle" element={<Bundle />} />
                <Route
                  path="/politica-privacidad"
                  element={<PoliticaPrivacidad />}
                />
                <Route
                  path="/terminos-condiciones"
                  element={<TerminosCondiciones />}
                />
                <Route
                  path="/consigue-tu-isbn"
                  element={<GetYourISBN />}
                />
                <Route
                  path="/bases-legales"
                  element={<LegalBases />}
                />
                <Route
                  path="/lector-cero"
                  element={<ReaderZero />}
                />
                <Route
                  path="/coaching"
                  element={<Coaching />}
                />
                <Route
                  path="/correccion-ortotipografica"
                  element={<OrtotypgraphicCorrection />}
                />
                <Route
                  path="/correccion-estilo"
                  element={<StyleCorrection />}
                />
                <Route
                  path="/maquetacion-digital"
                  element={<DigitalLayout />}
                />
                <Route
                  path="/servicio-lector-cero"
                  element={<ReaderZero />}
                />
                <Route
                  path="/servicio-coaching"
                  element={<Coaching />}
                />
                <Route
                  path="/servicio-correccion-ortotipografica"
                  element={<OrtotypgraphicCorrection />}
                />
                <Route
                  path="/servicio-correccion-estilo"
                  element={<StyleCorrection />}
                />
                <Route
                  path="/servicio-maquetacion-digital"
                  element={<DigitalLayout />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
          {!isPublic && !isLanding &&
            location.pathname !== "/aviso-legal" &&
            location.pathname !== "/condiciones-generales" &&
            location.pathname !== "/confirmacion-email" &&
            location.pathname !== "/politica-cookies" &&
            location.pathname !== "/politica-privacidad" &&
            location.pathname !== "/terminos-condiciones" &&
            location.pathname !== "/publica-tu-obra" &&
            location.pathname.indexOf("/editar-libro") === -1 &&
            location.pathname !== "/login" ? (
            <div className="pie">
              <Footer />
            </div>
          ) : null}
          {isPublic && (
            location.pathname === "/" ||
            location.pathname === "/por-que-bookolicos" ||
            location.pathname === "/quienes-somos" ||
            location.pathname.indexOf("/busqueda") !== -1
          ) ? (
            <div className="pie">
              <FooterPublic />
            </div>
          ) : null}
          {isPublic && (
            location.pathname === "/lista-autores" ||
            location.pathname === "/faqs" ||
            location.pathname === "/descubre" ||
            location.pathname === "/contacto" ||
            location.pathname === "/noticias" ||
            location.pathname.startsWith("/noticias/") ||
            location.pathname.startsWith("/libro-por-genero/") ||
            location.pathname.startsWith("/perfil-autor/")
          ) ? (
            <div className="pie">
              <FooterPublicTwo />
            </div>
          ) : null}
          {isLanding ? (
            <div className="pie">
              <FooterLanding />
            </div>
          ) : null}
        </div>
      </ErrorBoundary>
    </CookiesProvider>
  );
}

export default App;
