import Landing from "../landing/Landing";
import { useLocation } from "react-router-dom";

const DigitalLayout = () => {
  const location = useLocation();

  const content = {
    description: "En Bookólicos no dejamos nada al azar. ¿Tienes un libro y quieres publicarlo en formato electrónico? En este caso, más vale que sepas maquetar en InDesign, crear estilos CSS y corregir en lenguaje HTML; si no, puedes seguir cualquier tutorial de Youtube hasta que te des cuenta de que nada sale como esperas: quizá entonces sea el momento de tratar con un profesional que respetará el formato y lo embellecerá para que tu libro no genere saltos de página raros, no muestre subrayados en los enlaces ni ese horrible tono azul de la referencia web y se vea uniforme en todo su contenido.",
    summary: "Tu libro electrónico listo para subir a las plataformas en las que desees venderlo.",
    price: "1,60€ por franjas de 1000 matrices",
    formTitle: "Contrata el servicio de maquetación digital",
    formDescription: "Todos los servicios editoriales de Bookólicos se realizarán bajo presupuesto, previo contacto del autor con nosotros (vía email) para darnos detalles de su obra y sus necesidades."
  };
  let services = [];

  location.pathname.indexOf('servicio') !== -1 ?
    services = [{
      name: "Lector cero",
      link: "/servicio-lector-cero"
    }, {
      name: "Coaching",
      link: "/servicio-coaching"
    }, {
      name: "Corrección ortotipográfica",
      link: "/servicio-correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/servicio-correccion-estilo"
    }]
  :
    services = [{
      name: "Lector cero",
      link: "/lector-cero"
    }, {
      name: "Coaching",
      link: "/coaching"
    }, {
      name: "Corrección ortotipográfica",
      link: "/correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/correccion-estilo"
    }]
  ;

  return (
    <Landing
      service="Maquetación digital"
      content={content}
      services={services}
    />
  );
};

export default DigitalLayout;
