import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ls from "../services/localStorage";
import validateToken from "../services/validateToken";

export const useIsPublic = () => {
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState(false);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    setToken(tokenDecoded)
  }, []);

  const location = useLocation();

  return location.pathname === "/" ||
  location.pathname === "/descubre" ||
  location.pathname === "/quienes-somos" ||
  location.pathname === "/agenda" ||
  location.pathname === "/lista-autores" ||
  location.pathname === "/faqs" ||
  location.pathname === "/por-que-bookolicos" ||
  location.pathname.startsWith("/libros-por-genero/") ||
  location.pathname.startsWith("/perfil-autor/") ||
  (location.pathname.startsWith("/perfil-del-autor/") && !token) ||
  location.pathname === "/contacto" ||
  location.pathname === "/noticias" ||
  location.pathname === "/consigue-tu-isbn" ||
  location.pathname.startsWith("/noticias/") ||
  location.pathname.startsWith("/busqueda") || 
  location.pathname.startsWith("/listado-cursos") || 
  location.pathname === "/servicio-lector-cero" || 
  location.pathname === "/servicio-coaching" || 
  location.pathname === "/servicio-correccion-ortotipografica" || 
  location.pathname === "/servicio-correccion-estilo" || 
  location.pathname === "/servicio-maquetacion-digital";
}
