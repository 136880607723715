import "./Header.scss";

import ls from "../../services/localStorage";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logoHeader from "./../../styles/images/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Icon from "../Icon/Icon";
import Button from "../DesingSystem/Button/Button";
import Avatar from "../ProfileComponents/Avatar/Avatar";
import validateToken from "../../services/validateToken";
import { getUserById } from "../../redux/actions/user";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isServicesActive, setIsServicesActive] = useState(false);
  const isBookListActive = location.pathname === "/catalogo";
  const isCoursesActive = location.pathname === "/cursos";
  const isEventsActive = location.pathname === "/eventos";

  useEffect(() => {
    setIsServicesActive(false)
  }, [location]);

  const showSearch = () => {
    setTimeout(() => {
      setSearchOpen(true);
    }, 100);
  };

  const hideSearch = () => {
    console.log("hide search");
    setSearchOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.currentTarget.value !== '') {
      navigate('/buscador/?keyword=' + event.currentTarget.value);
      window.location.href = '/buscador/?keyword=' + event.currentTarget.value;
    }
  };

  const toggleServicesMenu = () => {
    isServicesActive ? setIsServicesActive(false) : setIsServicesActive(true);
  };

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if (tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    }
  }, []);

  return user ? (
    <section className="header">
      { isServicesActive &&
        <div className="header__full px-1 pb-5">
          <div className="px-5 mx-4 d-flex flex-row">
            <div style={{ width: '31%' }}>
              <Link
                className={`header__full__link d-flex align-items-center mb-4`}
                to={"/lector-cero"}
              >
                <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                <span>
                  Lector cero
                </span>
              </Link>
              <Link
                className={`header__full__link d-flex align-items-center mb-1`}
                to={"/correccion-estilo"}
              >
                <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                <span>
                  Correción de estilo
                </span>
              </Link>
            </div>

            <div style={{ width: '31%' }}>
              <Link
                className={`header__full__link d-flex align-items-center mb-4`}
                to={"/coaching"}
              >

                <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                <span>
                  Coaching
                </span>
              </Link>

              <Link
                className={`header__full__link d-flex align-items-center mb-1`}
                to={"/maquetacion-digital"}
              >

                <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                <span>
                  Maquetación digital
                </span>
              </Link>
            </div>

            <div style={{ width: '35%' }}>
              <Link
                className={`header__full__link d-flex align-items-center mb-4`}
                to={"/correccion-ortotipografica"}
              >

                <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                <span>
                  Corrección ortotipográfica
                </span>
              </Link>
            </div>
          </div>
        </div>
      }
      <div className={`header__left ${searchOpen ? "header__left--show" : ""}`}>
        <Link to={"/"}>
          <img
            className="header__left-img"
            src={logoHeader}
            alt="Logo Bookolicos"
          ></img>
        </Link>
        <div className="header__links">

          <Link
            className={`header__left-text ${
              isBookListActive ? "header__left-text--active" : ""
            }`}
            to={"/catalogo"}
          >
            Catálogo
          </Link>
          <Link
            className={`header__left-text ${
              isCoursesActive ? "header__left-text--active" : ""
            }`}
            to={'/cursos'}
          >
            Cursos
          </Link>
          <Link
            className={`header__left-text ${
              isEventsActive ? "header__left-text--active" : ""
            }`}
            to={'/eventos'}
          >
            Eventos
          </Link>
          <span
            className={`header__left-text d-flex align-items-center pointer ${
              isServicesActive === true ? "header__left-text--active" : ""
            }`}
            onClick={() => toggleServicesMenu()}
          >
            Servicios <Icon name="chevronDown" color="secondary-2" size="small" className="p-1 ms-1" />
          </span>
        </div>
        {!searchOpen && (
          <div className="header__icon-search">
            <Icon
              name="search"
              size="medium"
              color="secondary-1"
              onClick={showSearch}
            />
          </div>
        )}
        <div
          className={`header__input-container ${
            searchOpen ? "header__input-container--show" : ""
          }`}
        >
          <Icon name="search" size="medium" color="secondary-1" />
          <input
            className={`header__input ${
              searchOpen ? "header__input--show" : ""
            }`}
            placeholder="¿Qué buscas?"
            name="keyword"
            autoComplete="off"
            onKeyDown={handleKeyDown}
          ></input>
          {searchOpen && (
            <Icon
              name="close"
              size="medium"
              color="secondary-1"
              onClick={hideSearch}
            />
          )}
        </div>
      </div>
      <div className="header__right">
        <Button
          className="btn-orangebg"
          iconLeft="write"
          text="Publica tu obra"
          onClick={() => {
            navigate("/publica-tu-obra");
          }}
        />
        <Link className="header__right-profile" to="/profile/datos-generales">
          <Avatar
            user={{ firstName: user.firstName, lastName: user.lastName }}
            size={"s"}
          />
        </Link>
      </div>
    </section>
  ) : null;
}

export default Header;
