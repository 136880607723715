import "./CourseCard.scss";
import Icon from "../Icon/Icon";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import utils from '../../services/utils';

const CourseCard = (props) => {
  const course = props.course;
  const url = props.url;
  const navigate = useNavigate();
  const formatDate = (date) => {
    // Format date in Spanish to be day and month in text only first three letters
    dayjs.locale('es');
    return dayjs(date).format('DD MMM');
  }

  const getCourseHours = (course) => {
    if (!!course.weeklyHours) return `${course.weeklyHours} hrs semanales`
    if (!!course.totalHours) return `${course.totalHours} hrs totales`
  }

  return (
    <div className="course-card">
      <div className="course-card__image" onClick={() => navigate(`${url}`)}>
        <Icon name="arrowDiagonal" color="secondary-7" size="xlarge" />
        <img className="course-card__image-bg" src={course.picture_big} alt="" />
      </div>
      <div className="course-card__info">
        <div className="course-card__professor">Profesor: {course.teacher}</div>
        <div className="course-card__title">{course.title}</div>
        <div className="course-card__description">{ utils.createExcerpt(course.description.replaceAll('&lt;', '<').replace(/(<([^>]+)>)/gi, ""), 19) }</div>
        <div className="course-card__dates">
          <Icon name="calendar" color="secondary-2" />
          {course.date ? (
            <div className="course-card__dates-item">
              {course.date} { course.hours && <>|</> }
            </div>
          ) : (
            <div className="course-card__dates-item">
              Asíncrono { course.hours && <>|</> }
            </div>
          )}
          <div className="course-card__hours">
            <span>{course.hours}h {course.frequency == 'totales' && course.frequency}{course.frequency == 'semana' && 'a la semana'}{course.frequency == 'dia' && 'al día'}</span>
          </div>
        </div>
        <div className="btn btn-blackbg" onClick={() => navigate(`${url}`)}>Apúntate por {course.price}€</div>
      </div>
    </div>
  )
}

export default CourseCard;
