import Landing from "../landing/Landing";
import { useLocation } from "react-router-dom";

const OrtotypographicCorrection = () => {
  const location = useLocation();

  const content = {
    description: "Cuando un lector encuentra faltas de ortografía y de puntuación en un libro, esto, le saca de su lectura obligándole a releer. ¿Cuánto respeto tienes por tu lector? ¿Crees que tú mismo puedes corregir todo el texto? No te equivoques, todos los escritores pasan por este proceso en cada manuscrito que publican, incluso los superventas. Repasamos tu obra en busca de faltas de ortografía y de puntuación para adecuarla a las normas establecidas por la RAE: acentuación, latinismos, demostrativos, uso preposicional, puntuación, prosodia, prefijos y sufijos, etc. Te ayudamos a que el lector de tu libro obtenga el mejor producto posible para que pueda leer sin distracciones molestas.",
    summary: "Te devolvemos tu obra impoluta; lista para maquetar, imprimir y vender.",
    price: "0,80€ por franjas de 1000 matrices",
    formTitle: "Contrata una corrección ortotipográfico",
    formDescription: "Todos los servicios editoriales de Bookólicos se realizarán bajo presupuesto, previo contacto del autor con nosotros (vía email) para darnos detalles de su obra y sus necesidades."
  };

  let services = [];

  location.pathname.indexOf('servicio') !== -1 ?
    services = [{
      name: "Lector cero",
      link: "/servicio-lector-cero"
    }, {
      name: "Coaching",
      link: "/servicio-coaching"
    }, {
      name: "Corrección de estilo",
      link: "/servicio-correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/servicio-maquetacion-digital"
    }]
  :
    services = [{
      name: "Lector cero",
      link: "/lector-cero"
    }, {
      name: "Coaching",
      link: "/coaching"
    }, {
      name: "Corrección de estilo",
      link: "/correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/maquetacion-digital"
    }]
  ;

  return (
    <Landing
      service="Corrección ortotipográfica"
      content={content}
      services={services}
    />
  );
};

export default OrtotypographicCorrection;
