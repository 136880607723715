import "./Avatar.scss";

function Avatar(props) {
  const sizeClass = props.size ? `avatar__${props.size}` : 'avatar__s';

  return (
    <span className={'avatar ' + sizeClass + ' ' + props.className}>
      <span>
        { props.user ? props.user.firstName.charAt(0) : 'B' }
        { props.user ? props.user.lastName.charAt(0) : '' }
      </span>
    </span>
  );
}

export default Avatar;
