import "./Courses.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { types } from "../../constants/constants";
import NameSection from "../../components/NameSection/NameSection";
import ComponentInfo from "../../components/ComponentInfo/ComponentInfo";
import Filter from "../../components/Filter/Filter";
import CourseCard from "../../components/CourseCard/CourseCard";
import Button from "../../components/DesingSystem/Button/Button";
import FormCta from "../../components/FormCta/FormCta";
import { useNavigate } from "react-router-dom";
import { getApiCourses } from "../../services/apiCourse";

const Courses = (props) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState(null);
  const [totalCourses, setTotalCourses] = useState(0);
  const [limit, setLimit] = useState(types.PAGE_SIZE);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState(-1);
  const [categories, setCategories] = useState('[]');
  const user = useSelector((store) => store.user.user);

  const title = "Queremos ayudarte";
  const subtitle = "Tanto si estás empezando como si tienes experiencia en el sector, nuestra escuela online tiene todo aquello que necesitas.";

  const filters = [
    {
      title: 'Técnicas de escritura',
      items: [
        'Escritura creativa',
        'Personajes',
        'Diálogos',
        'Géneros',
        'Cine',
        'Estructuras narrativas',
        'No ficción',
        'Poesía'
      ]
    },
    {
      title: 'Post escritura',
      items: [
        'Maquetación',
        'Marketing',
        'Ventas',
        'Ilustración',
        'Legal',
        'Diseño de portadas',
        'Booktrailer'
      ]
    }
  ];

  useEffect(() => {
    if(!user){
      navigate("/listado-cursos");
    }else{
      navigate("/cursos");
    }
  }, [user]);
  
  useEffect(() => {
    getCourses();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    getCourses();
  }, [categories]);

  const getCourses = async () => {
    const response = await getApiCourses({ limit: limit, offset: offset, sortBy: sortBy, sortDirection: sortDirection, categories: categories });

    setCourses(response.courses);
    setTotalCourses(response.total);
  };

  const loadMoreResults = async () => {
    const offsetPage = offset + limit;
    setOffset(offsetPage);

    const response = await getApiCourses({ limit: limit, offset: offsetPage, sortBy: sortBy, sortDirection: sortDirection, categories: categories });

    setCourses(courses.concat(response.courses));
    setTotalCourses(response.total);
  };

  const doSort = async (filters) => {
    setCategories(JSON.stringify(filters.filtersSelected));

    if(filters.sortSelected === 'NEW'){ // De más antiguos a más recientes
      setSortBy('created_at');
      setSortDirection(1);
    }

    if(filters.sortSelected === 'OLD'){ // De más recientes a más antiguos
      setSortBy('created_at');
      setSortDirection(-1);
    }

    if(filters.sortSelected === 'PRICE_HIGH'){ // De más económicos a más costosos
      setSortBy('price');
      setSortDirection(1);
    }

    if(filters.sortSelected === 'PRICE_LOW'){ // De más costosos a más económicos
      setSortBy('price');
      setSortDirection(-1);
    }
  };


  return (
    <>
      <NameSection name="Cursos" color="#f05a24" />
      <ComponentInfo title={title} subtitle={subtitle} />
      <Filter
        title="Todos los cursos"
        filters={filters}
        onFilterChange={(filters) => doSort(filters)}
      >
          <div className="courses">
            {courses && courses.map((course, index) => (
              
              <CourseCard course={course} 
                url={ user ? "/curso/" + course.slug : "/listado-cursos/" + course.slug  } 
                key={course.slug} />
            ))}

            { courses && courses.length === 0 &&
              <p className="ms-4">No hay ningún curso disponible</p>
            }
          </div>
      </Filter>

      <div className="courses__load-more">
        { courses && (totalCourses > courses.length) &&
          <Button className={'btn-white'} text={'Cargar más cursos'} onClick={loadMoreResults} />
        }
      </div>
      {user ? 
        <FormCta text="¿Te gustaría que tu curso aparezca en la web de Bookólicos?" url="/cursos/formulario" />
      :
        <FormCta text="¿Te gustaría que tu curso aparezca en la web de Bookólicos?" url="/listado-cursos/formulario" />
      }
    </>
  );
};


export default Courses;
