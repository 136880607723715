import "./LandingHero.scss";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {string} props.description
 * @param {string} props.summary
 * @param {string} props.price
 */
const LandingHero = ({ description, summary, price }) => {
  return (
    <div className="landing-hero">
      <div className="landing-hero__description">
        <div>{description}</div>
        <div>
          <p>¿Qué obtendrás?</p>
          <p>{summary}</p>
        </div>
      </div>
      <div className="landing-hero__price">
        <span>Precio por el servicio</span>
        <h1>{price}</h1>
        <span>(Más 21% de IVA)</span>
      </div>
    </div>
  );
};
LandingHero.propTypes = {
  description: PropTypes.string,
  summary: PropTypes.string,
  price: PropTypes.string
};

export default LandingHero;
