import { types } from "../constants/constants";
import ls from "./localStorage";

export const getApiSales = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales${query}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSalesByCustomer = (attributes) => {
  const esc = encodeURIComponent;
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/customer/${attributes.user_id}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSalesCoursesByCustomer = (attributes) => {
  const esc = encodeURIComponent;
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/courses/customer/${attributes.user_id}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSalesBySeller = (attributes) => {
  const esc = encodeURIComponent;
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/seller/${attributes.user_id}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSalesByBook = (attributes) => {
  const esc = encodeURIComponent;
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/book/${attributes.book_id}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSale = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}books/id/${attributes.sale_id}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSaleDownloadBook = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");

  return fetch(`${types.API_URL}download-ebook${query}`, {
    headers: { token: token }
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.blob()
  });
};

export const postApiCreateSale = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}sale/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });

};

export const postApiCreateIsbnSale = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}sale/isbn/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });

};

export const postApiCreateSaleCourse = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}sale/course/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: token
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });

}

export const putApiLikeSale = async (saleId, message = '') => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}sales/id/${saleId}/like`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({message: message})
  })

  if (!response.ok) {
    const responseJson = await response.json()
    throw new Error(responseJson)
  }
  return response.json()
};

export const putApiDislikeSale = async (saleId, message = '') => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}sales/id/${saleId}/dislike`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({message: message})
  })

  if (!response.ok) {
    const responseJson = await response.json()
    throw new Error(responseJson)
  }
  return response.json()
};

export const putApiNeutralSale = async (saleId, message = '') => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}sales/id/${saleId}/neutral`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({message: message})
  })

  if (!response.ok) {
    const responseJson = await response.json()
    throw new Error(responseJson)
  }
  return response.json()
};

export const putApiRemoveSaleReview = async (saleId) => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}sales/id/${saleId}/remove-review`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    }
  })

  if (!response.ok) {
    const responseJson = await response.json()
    throw new Error(responseJson)
  }
  return response.json()
};

export const putApiUpdateSale = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/update/id/${attributes.payment_intent}`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });

};

export const putApiUpdateSaleCourse = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}sales/course/update/id/${attributes.payment_intent}`, {
    method: "PUT",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });

};
