import * as dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import AccordionFaqs from "../../components/AccordionFaqs/AccordionFaqs";
import CourseCard from "../../components/CourseCard/CourseCard";
import Button from "../../components/DesingSystem/Button/Button";
import ModalBuy from "../../components/ModalBuy/ModalBuy";
import Tabs from "../../components/Tabs/Tabs";
import sanitizeHtml from 'sanitize-html';
import { getApiCourseBySlug, getApiCourses } from "../../services/apiCourse";
import { postApiCreateSaleCourse, putApiUpdateSaleCourse } from '../../services/apiSale';
import "./CourseDetail.scss";

const CourseDetail = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [courses, setCourses] = useState(null);
  const [course, setCourse] = useState(null);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(0);
  const slider = useRef(null);
  const user = useSelector((store) => store.user.user);

  const [showBuyModal, setShowBuyModal] = useState(false);

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    className: "related-courses__slider",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
          adaptiveHeight: false
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
          adaptiveHeight: false
        },
      },
    ],
  };

  useEffect(() => {
    if(!user){
      navigate("/listado-cursos/" + urlParams.slug);
    }else{
      navigate("/curso/" + urlParams.slug);
    }
  }, [user]);

  useEffect(() => {
    getCourse();
    getCourses();
  }, [user]);

  const getCourse = async () => {
    const response = await getApiCourseBySlug({ slug: urlParams.slug });

    setCourse(response);
  };

  const getCourses = async () => {
    const response = await getApiCourses({ limit: limit, offset: offset });

    setCourses(response.courses);
  };

  const faqs = [
    {
      question: "¿Qué son los cursos de Bookólicos?",
      answer: "En Bookólicos queremos ayudarte a crecer como escritor y tener formación es un plus añadido a la creatividad. Por eso hemos preparado esta sección de la web en la que podrás encontrar cursos sobre temas específicos que impulsen tus técnicas de escritura y cursos que arrojen luz a todos aquellos aspectos a tener en cuenta después de terminar de escribir. <br /><br />Algunos de estos cursos los imparten profesionales del equipo de Bookólicos (correctores, escritores, guionistas, etc), pero creemos que hay otros muchos profesionales en activo que tienen mucho que aportar y también queremos darles cabida dentro de nuestra comunidad. Todos los cursos que encontrarás en nuestra plataforma han pasado un cribado de calidad para ofrecerte solo aquello que de verdad pueda serte útil."
    },
    {
      question: "¿Qué cursos encuentro en Bookólicos?",
      answer: "Vamos a ir incorporando cursos en dos secciones diferenciadas: <br /><b>Técnicas de escritura:</b> donde encontrarás cursos relacionados con escritura creativa, personajes, estructuras narrativas, géneros, cine, etc. <br /><b>Después del proceso de escritura:</b> donde encontrarás cursos relacionados con diseño de portadas, maquetación, marketing, etc."
    },
    {
      question: "¿Dónde encuentro información detallada del curso?",
      answer: "En la propia ficha del curso encontrarás toda la información del mismo. Quién lo imparte, qué contenido vas a encontrar y las lecciones en la que se divide, el nivel del curso, las horas que te llevará completarlo y los materiales o requisitos necesarios para realizarlo."
    },
    {
      question: "¿Cuándo empiezan y cuando acaban los cursos?",
      answer: "La mayoría de los cursos que encontrarás en Bookólicos podrás hacerlos en cualquier momento y lugar. Los contenidos estarán disponibles para que los puedas realizar a tu ritmo, sin presiones, y puedas volver a una lección en cualquier momento para repasar. En el caso de cursos que tengan fechas delimitadas por el propio profesor, la información se verá reflejada en la ficha del curso."
    },
    {
      question: "¿Cómo me apunto a curso de Bookólicos??",
      answer: "Crea tu cuenta en Bookólicos o accede a tu perfil habitual. <br />Si entras por primera vez, pulsa sobre <b>acceder</b> y sigue los pasos. Podrás crear un usuario y contraseña o entrar con tu cuenta de Google o Facebook. <br />Una vez dentro de tu perfil, pincha sobre la sección de <b>cursos</b>, que encontrarás en el menú principal, y elige el curso que te interese."
    }
  ];


  const daysParser = (days) => {
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const parsedDays = days.map(day => daysOfWeek[day - 1]);
    const lastDay = parsedDays.pop();
    return parsedDays.join(', ') + ' y ' + lastDay;
  };

  const formatDate = (date) => {
    // Format date in Spanish
    dayjs.locale('es');
    return dayjs(date).format('DD MMM YYYY');
  };

  const createSale = async (payment_intent) => {
    await postApiCreateSaleCourse({
      salecourse: {
        amount: course.price * 100,
        customer: user._id,
        course: course._id,
        payment_intent,
        status: 0,
      },
    });
  };
  
  return (
    <>
      <Container>
        <div className="course-detail">
          {course &&
            <>
              <div className="course-detail__image">
                <img src={course.picture_big} alt={course.title} />
              </div>
              <div className="course-detail__content">
                <div className="course-detail__info">
                  <p className="course-detail__professor">Por {course.teacher}</p>
                  <h1 className="course-detail__title">{course.title}</h1>
                  <Tabs content={[
                    {
                      title: "Descripción",
                      htmlData: sanitizeHtml(course.description.replaceAll('&lt;', '<'), {
                        allowedTags: [ 'p', 'b', 'i', 'strong', 'a', 'ul', 'li', 'br' ],
                        allowedAttributes: {
                          'a': [ 'href' ]
                        }
                      })
                    },
                    {
                      title: "Contenidos y temas",
                      htmlData: sanitizeHtml(course.content.replaceAll('&lt;', '<'), {
                        allowedTags: [ 'p', 'b', 'i', 'strong', 'a', 'ul', 'li', 'br' ],
                        allowedAttributes: {
                          'a': [ 'href' ]
                        }
                      })
                    },
                    {
                      title: "Información relevante",
                      htmlData: sanitizeHtml(course.information.replaceAll('&lt;', '<'), {
                        allowedTags: [ 'p', 'b', 'i', 'strong', 'a', 'ul', 'li', 'br' ],
                        allowedAttributes: {
                          'a': [ 'href' ]
                        }
                      })
                    }
                  ]} />
                </div>
                <div className="course-detail__summary">
                  {course.type === 'sincrono' ? (
                    <>
                      <div className="course-detail__summary-dates">
                        {course.date}
                      </div>
                      <div className="course-detail__summary-dates-info">
                        <span>{course.days}</span> {course.hours && <>| {course.hours}h {course.frequency == 'totales' && course.frequency}{course.frequency == 'semana' && 'a la semana'}{course.frequency == 'dia' && 'al día'}</>}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="course-detail__summary-dates">
                        Curso asíncrono
                      </div>
                      <div className="course-detail__summary-dates-info">
                        <span>A tu ritmo</span> {course.hours && <>| {course.hours}h {course.frequency == 'totales' && course.frequency}{course.frequency == 'semana' && 'a la semana'}{course.frequency == 'dia' && 'al día'}</>}
                      </div>
                    </>
                  )}
                  <div className="course-detail__summary-buy">
                    <div className="course-detail__summary-price">
                      {course.price}€
                      <p>Precio total</p>
                    </div>
                    <div className="course-detail__summary-btn">
                      { user ?
                        <button className="btn btn-orangebg" onClick={() => setShowBuyModal(true)}>Comprar curso</button>
                      :
                        <button className="btn btn-orangebg" onClick={() => navigate('/login')}>Comprar curso</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
        <AccordionFaqs faqs={faqs} />
        <div className="course-detail__banner">
          <h2 className="course-detail__banner-title">¿Tienes alguna duda sobre el curso?</h2>
          <Button text="Contáctanos" className="btn btn-orangebg" onClick={() => navigate('/contacta-con-nosotros')} />
        </div>
      </Container>
      { courses && courses.length > 1 ?
        <div className="related-courses">
          <div className="related-courses__header">
            <h2 className="related-courses__title">Más cursos</h2>
            <Button text="Ver todos" className="btn btn-white" onClick={() => navigate('/cursos')} />
          </div>
          <div className="related-courses__list">
            <Slider {...settings} ref={slider}>
              {courses && courses.slice(0, 3).map((course, index) => (
                <CourseCard course={course} 
                url={ user ? "/curso/" + course.slug : "/listado-cursos/" + course.slug } 
                key={course.title} />
              ))}
            </Slider>
          </div>
          <div className="related-courses__footer">
            <Button
              className={'btn-blackbg'}
              text={'Ver todos'}
              onClick={() => navigate('/cursos')}
              iconRight={'arrowRight'}
            />
          </div>
        </div>
      : null }
      {course && user ? <ModalBuy
        price={course.price}
        show={showBuyModal}
        onHide={() => setShowBuyModal(false)}
        onCreateSale={createSale}
        confirmPaymentConfig={{ redirect: 'if_required' }}
        confirmSetupConfig={{ redirect: 'if_required' }}
        onPaymentSuccess={
          async response => {
            await putApiUpdateSaleCourse({
              payment_intent : response.paymentIntent.id,
              status : 1
            })
          }
        }
        secondStep={<div className="buy-book pt-3">
          <h3 className="buy-book__title text-center">
            ¡Enhorabuena! Tu compra se ha completado con éxito
          </h3>
          <p className="buy-book__text-download">
            En breve recibirás un correo con la confirmación de tu compra y el enlace para acceder al curso.
          </p>
          <div className="buy-book__container-button"> 
            <Button
              className="btn-orange mt-4"
              text="Ir a mis cursos"
              onClick={() => navigate("/profile/tus-cursos")}
            />
            <Button
              className="btn-gray mt-4"
              text="Cerrar"
              onClick={() => setShowBuyModal(false)}
            />
          </div>
        </div>}
      /> : null}
    </>
  );
};

export default CourseDetail;
