import "./HeaderMobile.scss";

import ls from "../../services/localStorage";
import {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import logoHeader from "./../../styles/images/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Icon from "../Icon/Icon";
import Button from "../DesingSystem/Button/Button";
import Avatar from "../ProfileComponents/Avatar/Avatar";
import validateToken from "../../services/validateToken";
import { getUserById } from "../../redux/actions/user";
import { Transition } from 'react-transition-group';

function HeaderMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const [menuContentOpen, setMenuContentOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const isActive = location.pathname === "/catalogo";
  const nodeRef = useRef(null);

  useEffect(() => {
    hideMenuContent()
    closeServices()
  }, [location]);

  const showMenuContent = () => {
    setMenuContentOpen(true);
  };

  const hideMenuContent = () => {
    setMenuContentOpen(false);
  };

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if (tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    }
  }, []);
  const duration = 300;

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { display: 'flex', opacity: 0 },
    entered: { display: 'flex', opacity: 1, transform: 'none'},
    exiting: { display: 'flex', opacity: 0, transform: 'translateY(2rem)' },
    exited: { display: 'none', opacity: 0, transform: 'translateY(2rem)' }
  };

  const search = (e) => {
    const keyword = e.target.value;
    if (e.key === "Enter" && e.currentTarget.value !== '') {
      navigate('/buscador/?keyword=' + keyword);
      window.location.href = '/buscador/?keyword=' + keyword;
      hideMenuContent();
    }
  }

  const openServices = () => {
    setIsServicesOpen(true)
  }

  const closeServices = () => {
    setIsServicesOpen(false)
  }

  return user ? (
    <section className="headerMobile">
      <div className={`headerMobile__left`}>
        <Link to={"/"}>
          <img
            className="headerMobile__left-img"
            src={logoHeader}
            alt="Logo Bookolicos"
          ></img>
        </Link>
      </div>
      <div className={'headerMobile__right'}>
        <Icon name="menu" size='medium' color={'secondary-1'} onClick={showMenuContent}></Icon>
      </div>


      <Transition
        in={menuContentOpen}
        nodeRef={nodeRef}
        timeout={100}
      >
        {state => (
        <div
          // className={`headerMobile__menu-container ${menuContentOpen && `headerMobile__menu-container--active`}`}
          className={`headerMobile__menu-container`}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          ref={nodeRef}
        >
          <div className="headerMobile__menu-header justify-content-between">
            <Icon name={'close'} size={'medium'} onClick={hideMenuContent} />
            { isServicesOpen &&
              <Icon name={'arrowLeft'} size={'medium'} color={'secondary-1'} onClick={()=>closeServices()} /> }
          </div>
          <div className="headerMobile__menu-content">
            { isServicesOpen ? 
              <>
                <h3 className="headerMobile__menu-content-title">Servicios</h3>
                <ul className="headerMobile__menu-list mb-5">
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/lector-cero"}>Lector cero</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/correccion-estilo"}>Corrección de estilo</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/coaching"}>Coaching</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/correccion-ortotipografica"}>Corrección ortotipográfica</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/maquetacion-digital"}>Maquetación digital</Link>
                  </li>
                </ul>
              </>
            :
              <>
                <h3 className="headerMobile__menu-content-title">Menu</h3>
                <ul className="headerMobile__menu-list">
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/catalogo"}>Catálogo</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/cursos"}>Cursos</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/eventos"}>Eventos</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/publica-tu-obra"}>Publica tu obra</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <div className="d-flex align-items-center justify-content-between pointer" onClick={() => openServices()}>
                      <span>Servicios</span>
                      <Icon name={'chevronRight'} size={'medium2'} color={'secondary-1'} />
                    </div>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <Link to={"/profile/datos-generales"}>Mi perfil</Link>
                  </li>
                </ul>
              </>
            }
            <div className="headerMobile__menu-search">
              <Icon name="search" size="medium" color="secondary-3" />
              <input
                className="headerMobile__menu-search-input"
                placeholder="Buscar obra, género, autor"
                name="keyword"
                autoComplete="off"
                onKeyDown={search}
               />
            </div>
          </div>

          <div className="headerMobile__menu-content">
            <h3 className="headerMobile__menu-content-title">Contáctanos</h3>
            <ul className='headerMobile__menu-list'>
              <li className="headerMobile__menu-list-item--small">
                <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a>
              </li>
              { /* <li className="headerMobile__menu-list-item--small">
                <a href="tel:+344423423423423">+34 31283771283</a>
              </li> */ }
            </ul>
          </div>
        </div>
        )}
      </Transition>
    </section>
  ) : null;
}

export default HeaderMobile;
