import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { isValidNif, isValidCif } from "nif-dni-nie-cif-validation";
import { types } from "../../../constants/constants";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import "./GeneralData.scss";

import { getUserById, updateUser } from "../../../redux/actions/user";
import { getApiSalesByCustomer } from '../../../services/apiSale';
import {
  getStripePaymentMethods,
  postApiCreateSetupIntent,
  postApiDeletePaymentMethod,
  postApiCreateConnectedAccount,
  postApiGetStripeOnboardingLink,
  getStripeConnectedAccount,
  deleteApiDeleteConnectedAccount
} from "../../../services/apiUser";
import poweredByStripe from "../../../styles/images/powered-by-stripe.svg";
import CheckoutForm from "../../CheckoutForm/CheckoutForm";
import Button from "../../DesingSystem/Button/Button";
import CheckBox from "../../DesingSystem/CheckBox/CheckBox";
import InputText from "../../DesingSystem/InputText/InputText";
import ModalMessage from "../../DesingSystem/ModalMessage/ModalMessage";
import Select from "../../DesingSystem/Select/Select";
import Icon from "../../Icon/Icon";

function GeneralData() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const errorMessage = useSelector((store) => store.user.errorMessage);
  const [error, setError] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nif, setNif] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [connectedAccount, setConnectedAccount] = useState(null);
  const [btnStripeConnectButtonLoading, setBtnStripeConnectButtonLoading] = useState(false);
  const [addCreditCardForm, setAddCreditCardForm] = useState(false);
  const [addBankAccountForm, setAddBankAccountForm] = useState(false);
  const [companyFirstName, setCompanyFirstName] = useState("");
  const [companyLastName, setCompanyLastName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyDayBirth, setCompanyDayBirth] = useState("");
  const [companyMonthBirth, setCompanyMonthBirth] = useState("");
  const [companyYearBirth, setCompanyYearBirth] = useState("");
  const [companyCIF, setCompanyCIF] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyZipcode, setCompanyZipcode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyRelationship, setCompanyRelationship] = useState("");
  const [companyIBAN, setCompanyIBAN] = useState("");
  const [stripeTOS, setStripeTOS] = useState(false);
  const [showStripeTOS, setShowStripeTOS] = useState(false);
  const [optionsCheckout, setOptionsCheckout] = useState({});
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isNifEntered, setIsNifEntered] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [isAddressEntered, setIsAddressEntered] = useState(false);
  const [isPostalcodeEntered, setIsPostalcodeEntered] = useState(false);
  const [isLastNameEntered, setIsLastNameEntered] = useState(false);
  const [isCompanyFirstNameEntered, setIsCompanyFirstNameEntered] = useState(false);
  const [isCompanyLastNameEntered, setIsCompanyLastNameEntered] = useState(false);
  const [isCompanyEmailEntered, setIsCompanyEmailEntered] = useState(false);
  const [isCompanyPhoneEntered, setIsCompanyPhoneEntered] = useState(false);
  const [isCompanyDayBirthEntered, setIsCompanyDayBirthEntered] = useState(false);
  const [isCompanyMonthBirthEntered, setIsCompanyMonthBirthEntered] = useState(false);
  const [isCompanyYearBirthEntered, setIsCompanyYearBirthEntered] = useState(false);
  const [isCompanyCIFEntered, setIsCompanyCIFEntered] = useState(false);
  const [isCompanyAddressEntered, setIsCompanyAddressEntered] = useState(false);
  const [isCompanyZipcodeEntered, setIsCompanyZipcodeEntered] = useState(false);
  const [isCompanyCityEntered, setIsCompanyCityEntered] = useState(false);
  const [isCompanyNameEntered, setIsCompanyNameEntered] = useState(false);
  const [isCompanyRelationshipEntered, setIsCompanyRelationshipEntered] = useState(false);
  const [isCompanyIBANEntered, setIsCompanyIBANEntered] = useState(false);
  const [saveDataIcon, setSaveDataIcon] = useState("");
  const [saveDataButtonDisabled, setSaveDataButtonDisabled] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("ES");
  const [stripeErrors, setStripeErrors] = useState(undefined);
  const [showDeleteBankAccountModal, setShowDeleteBankAccountModal] = useState(false);
  const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] = useState(false);

  const [cookies, setCookie] = useCookies();
  const [showLikeReminderModal, setShowLikeReminderModal] = useState(false);

  const stripe = loadStripe(types.STRIPE_API_KEY);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    dispatch(getUserById(tokenDecoded.user._id));
  }, []);

  async function getPaymentMethods() {
    const paymentMethods = await getStripePaymentMethods(user._id);
    setAddCreditCardForm(false);
    setPaymentMethods(paymentMethods);
  }

  async function getConnectedAccountInfo() {
    const connectedAccount = await getStripeConnectedAccount(user._id);
    setConnectedAccount(connectedAccount);
  }

  async function checkForPurchasesAndLikeReminder() {
    const response = await getApiSalesByCustomer({
      user_id: user._id
    });

    setShowLikeReminderModal(!cookies.bookolicos_like_reminder_shown && response.total > 0);
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setNif(user.nif);
      setPhone(user.phone);
      setAddress(user.address.address);
      setPostalcode(user.address.postalCode);

      getPaymentMethods();

      getConnectedAccountInfo();

      checkForPurchasesAndLikeReminder();
    }
  }, [user]);

  useEffect(() => {
    /*if (!user) return null;*/
    // Make .buttons__containers position relative when .general-data bottom is visible on screen
    // And remove it when it's not visible when scroll
    // This is to avoid the buttons to overlap the footer
    const generalData = document.querySelector(".general-data");
    const buttonContainers = document.querySelector(".buttons__containers");

    const handleScroll = () => {
      const generalDataBottom = generalData.getBoundingClientRect().bottom + 155;
      if (generalDataBottom <= window.innerHeight) {
        buttonContainers.classList.remove("buttons__containers--fixed");
      } else {
        buttonContainers.classList.add("buttons__containers--fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [user]);

  const closeLikeReminderModal = () => {
    setShowLikeReminderModal(false);
    setCookie('bookolicos_like_reminder_shown', true);
  };

  //#region General Data

  const handleInputNameFocus = () => {
    setIsNameEntered(true);
  };

  const handleInputNameBlur = () => {
    setIsNameEntered(false);
  };

  const handleInputNifFocus = () => {
    setIsNifEntered(true);
  };

  const handleInputNifBlur = () => {
    setIsNifEntered(false);
  };

  const handleInputPhoneFocus = () => {
    setIsPhoneEntered(true);
  };

  const handleInputPhoneBlur = () => {
    setIsPhoneEntered(false);
  };

  const handleInputAddressFocus = () => {
    setIsAddressEntered(true);
  };

  const handleInputAddressBlur = () => {
    setIsAddressEntered(false);
  };

  const handleInputPostalcodeFocus = () => {
    setIsPostalcodeEntered(true);
  };

  const handleInputPostalcodeBlur = () => {
    setIsPostalcodeEntered(false);
  };

  const handleInputLastNameFocus = () => {
    setIsLastNameEntered(true);
  };

  const handleInputLastNameBlur = () => {
    setIsLastNameEntered(false);
  };

  //#endregion General Data

  //#region Company autonomo

  const handleInputCompanyFirstNameFocus = () => {
    setIsCompanyFirstNameEntered(true);
  };

  const handleInputCompanyFirstNameBlur = () => {
    setIsCompanyFirstNameEntered(false);
  };

  const handleInputCompanyLastNameFocus = () => {
    setIsCompanyLastNameEntered(true);
  };

  const handleInputCompanyLastNameBlur = () => {
    setIsCompanyLastNameEntered(false);
  };

  const handleInputCompanyEmailFocus = () => {
    setIsCompanyEmailEntered(true);
  };

  const handleInputCompanyEmailBlur = () => {
    setIsCompanyEmailEntered(false);
  };

  const handleInputCompanyPhoneFocus = () => {
    setIsCompanyPhoneEntered(true);
  };

  const handleInputCompanyPhoneBlur = () => {
    setIsCompanyPhoneEntered(false);
  };

  const handleInputCompanyDayBirthFocus = () => {
    setIsCompanyDayBirthEntered(true);
  };

  const handleInputCompanyDayBirthBlur = () => {
    setIsCompanyDayBirthEntered(false);
  };

  const handleInputCompanyMonthBirthFocus = () => {
    setIsCompanyMonthBirthEntered(true);
  };

  const handleInputCompanyMonthBirthBlur = () => {
    setIsCompanyMonthBirthEntered(false);
  };

  const handleInputCompanyYearBirthFocus = () => {
    setIsCompanyYearBirthEntered(true);
  };

  const handleInputCompanyYearBirthBlur = () => {
    setIsCompanyYearBirthEntered(false);
  };

  const handleInputCompanyCIFFocus = () => {
    setIsCompanyCIFEntered(true);
  };

  const handleInputCompanyCIFBlur = () => {
    setIsCompanyCIFEntered(false);
  };

  const handleInputCompanyAddressFocus = () => {
    setIsCompanyAddressEntered(true);
  };

  const handleInputCompanyAddressBlur = () => {
    setIsCompanyAddressEntered(false);
  };

  const handleInputCompanyZipcodeFocus = () => {
    setIsCompanyZipcodeEntered(true);
  };

  const handleInputCompanyZipcodeBlur = () => {
    setIsCompanyZipcodeEntered(false);
  };

  const handleInputCompanyCityFocus = () => {
    setIsCompanyCityEntered(true);
  };

  const handleInputCompanyCityBlur = () => {
    setIsCompanyCityEntered(false);
  };

  //#endregion Company autonomo

  const handleInputCompanyIBANFocus = () => {
    setIsCompanyIBANEntered(true);
  };

  const handleInputCompanyIBANBlur = () => {
    setIsCompanyIBANEntered(false);
  };

  const handleTOSChange = (event) => {
    setStripeTOS(event.target.checked);
  };

  //#region Company empresa

  const handleInputCompanyNameFocus = () => {
    setIsCompanyNameEntered(true);
  };

  const handleInputCompanyNameBlur = () => {
    setIsCompanyNameEntered(false);
  };

  const handleInputCompanyRelationshipFocus = () => {
    setIsCompanyRelationshipEntered(true);
  };

  const handleInputCompanyRelationshipBlur = () => {
    setIsCompanyRelationshipEntered(false);
  };
  //#endregion Company empresa

  const saveData = () => {
    setSaveDataButtonDisabled(true);
    setSaveDataIcon("loaderIcon");
    
    if (nif === "") {
      let errorObject = {};
      errorObject.nif = <p className="register-user__error">Debes poner tu DNI/CIF</p>;
      setError(errorObject);
      setSaveDataIcon("");
      setSaveDataButtonDisabled(false);
      document.getElementById('personal-data').scrollIntoView({ behavior: 'smooth' });
    } else if (isValidNif(nif) !== true && isValidCif(nif) !== true){
      let errorObject = {};
      errorObject.nif = <p className="register-user__error">El valor introducido no es correcto</p>;
      setError(errorObject);
      setSaveDataIcon("");
      setSaveDataButtonDisabled(false);
      document.getElementById('personal-data').scrollIntoView({ behavior: 'smooth' });
    }else{
      let errorObject = {};
      setError(errorObject); // Clean object
      let attributes = {
        user: {},
      };
      attributes.user._id = user._id;
      attributes.user.email = user.email;
      attributes.user.nickName = user.nickName;
      attributes.user.roleId = user.role._id;
      attributes.user.firstName = firstName;
      attributes.user.lastName = lastName;
      attributes.user.nif = nif;
      attributes.user.phone = phone;
      attributes.user.address = {
        address: address,
        postalCode: parseInt(postalcode),
      };

      dispatch(updateUser(attributes)).then(() => {
        setSaveDataIcon("");
        setSaveDataButtonDisabled(false);
      });
      
    }
  };

  const addCreditCard = async () => {
    try {
      if (!addCreditCardForm) {
        const pi = await postApiCreateSetupIntent({
          customer_id: user.stripe_customer,
        });
        setOptionsCheckout({
          locale: "es",
          clientSecret: pi.secret,
          appearance: {
            theme: "flat",
            rules: {
              ".Tab": {
                border: "1px solid #979797",
                backgroundColor: "white",
                marginBottom: "8px"
              },
              ".Tab--selected": {
                color: "#323232",
                border: "1px solid #979797",
                backgroundColor: "#F7F7F7"
              },
              ".Tab--selected:hover": {
                color: "#323232",
              },
              ".Tab:focus": {
                outline: "none",
                boxShadow: 0
              },
              ".Tab--selected:focus": {
                outline: "none",
                boxShadow: 0
              },
              ".TabIcon--selected": {
                fill: "#323232"
              },
              ".TabIcon--selected:hover": {
                fill: "#323232"
              },
              ".Label": {
                fontSize: "12px",
                textTransform: "uppercase",
                color: "#656565",
                marginBottom: "10px",
              },
              ".Input": {
                border: "1px solid #656565",
                borderRadius: "32px",
                backgroundColor: "#ffffff",
                paddingTop: "8px",
                paddingBottom: "8px",
                lineHeight: "24px",
              },
            },
          },
        });
        setAddCreditCardForm(true);
      } else {
        setAddCreditCardForm(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCreditCard = async (payment_method_id) => {
    try {
      const pi = await postApiDeletePaymentMethod({
        payment_method_id: payment_method_id,
      });
      const paymentMethods = await getStripePaymentMethods(user._id);
      setPaymentMethods(paymentMethods);
    } catch (error) {
      console.log(error);
    }
  };

  const addBankAccount = async () => {
    if (!addBankAccountForm) {
      setAddBankAccountForm(true);
      setCompanyType('autonomo');
    } else {
      setAddBankAccountForm(false);
    }
  };

  const deleteBankAccount = async () => {
    let attributes = {
      user: {},
    };
    attributes.user._id = user._id;

    try {
      const responseDelete = await deleteApiDeleteConnectedAccount(attributes);
      setConnectedAccount(undefined);
      setShowDeleteBankAccountModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const createConnetedAccount = async () => {
    setBtnStripeConnectButtonLoading(true)
    const account = await postApiCreateConnectedAccount({})

    if(account.statusCode === 400){
      setStripeErrors({
        stripeAPI : account.raw.message,
      })

      console.log(stripeErrors)
    }else{
      setStripeErrors({})
      const responseLink = await postApiGetStripeOnboardingLink({ 
        account : account.account.id,
        refresh_url : window.location.href,
        return_url : window.location.href 
      });
      console.log(JSON.stringify(responseLink.link.url))
      window.location.href = responseLink.link.url;
    }
  }

  const updateConnetedAccount = async (account_id) => {
    setBtnStripeConnectButtonLoading(true)

    const responseLink = await postApiGetStripeOnboardingLink({ 
      account : account_id,
      refresh_url : window.location.href,
      return_url : window.location.href,
      update: true
    });
    console.log(JSON.stringify(responseLink.link.url))
    window.location.href = responseLink.link.url;
  }

  const saveBankAccount = async () => {
    let error = false;
    let formErrorsTemp = [];

    console.log(stripeTOS);

    if (!country) { formErrorsTemp.country = 'El país es obligatorio'; error = true; }
    if (!companyFirstName) { formErrorsTemp.companyFirstName = 'El nombre es obligatorio'; error = true; }
    if (!companyLastName) { formErrorsTemp.companyLastName = 'Los apellidos son obligatorios'; error = true; }
    if (!companyEmail) { formErrorsTemp.companyEmail = 'El email es obligatorio'; error = true; }
    if (!companyPhone) { formErrorsTemp.companyPhone = 'El teléfono es obligatorio'; error = true; }
    if (!companyDayBirth) { formErrorsTemp.companyDayBirth = 'Obligatorio'; error = true; }
    if (!companyMonthBirth) { formErrorsTemp.companyMonthBirth = 'Obligatorio'; error = true; }
    if (!companyYearBirth) { formErrorsTemp.companyYearBirth = 'Obligatorio'; error = true; }
    if (!companyAddress) { formErrorsTemp.companyAddress = 'La dirección es obligatoria'; error = true; }
    if (!companyZipcode) { formErrorsTemp.companyZipcode = 'El código postal es obligatorio'; error = true; }
    if (!companyCity) { formErrorsTemp.companyCity = 'La ciudad es obligatoria'; error = true; }
    if (!companyIBAN) { formErrorsTemp.companyIBAN = 'El IBAN es obligatorio'; error = true; }
    if (companyType === 'empresa' && !companyName) { formErrorsTemp.companyName = 'El nombre de la empresa es obligatorio'; error = true; }
    if (companyType === 'empresa' && !companyRelationship) { formErrorsTemp.companyRelationship = 'El cargo es obligatorio'; error = true; }
    if (stripeTOS === false) { formErrorsTemp.StripeTOS = 'Debes aceptar los terminos y condiciones de Stripe'; error = true; }

    setStripeErrors({
      country: formErrorsTemp.country,
      companyFirstName: formErrorsTemp.companyFirstName,
      companyLastName: formErrorsTemp.companyLastName,
      companyEmail: formErrorsTemp.companyEmail,
      companyPhone: formErrorsTemp.companyPhone,
      companyDayBirth: formErrorsTemp.companyDayBirth,
      companyMonthBirth: formErrorsTemp.companyMonthBirth,
      companyYearBirth: formErrorsTemp.companyYearBirth,
      companyAddress: formErrorsTemp.companyAddress,
      companyZipcode: formErrorsTemp.companyZipcode,
      companyCity: formErrorsTemp.companyCity,
      companyIBAN: formErrorsTemp.companyIBAN,
      companyName: formErrorsTemp.companyName,
      companyRelationship: formErrorsTemp.companyRelationship,
      StripeTOS: formErrorsTemp.StripeTOS,
    });

    if (!error) {
      const response = await fetch("https://ipapi.co/json/");
      const dataIp = await response.json();
      const ip = dataIp.ip;

      const account = await postApiCreateConnectedAccount({
        companyName: companyName,
        companyFirstName: companyFirstName,
        companyLastName: companyLastName,
        companyEmail: companyEmail,
        companyPhone: (companyPhone.indexOf('+34') !== -1 ? companyPhone : '+34' + companyPhone),
        companyDayBirth: companyDayBirth,
        companyMonthBirth: companyMonthBirth,
        companyYearBirth: companyYearBirth,
        companyAddress: companyAddress,
        companyZipcode: companyZipcode,
        companyCity: companyCity,
        companyIBAN: companyIBAN,
        companyRelationship: companyRelationship,
        companyType: companyType,
        country: country,
        ip: ip
      });

      if (account.statusCode === 400) {
        setStripeErrors({
          stripeAPI: account.raw.message,
        });

        console.log(stripeErrors);
      } else {
        setStripeErrors({});
        window.location.reload(true);
      }
    }
  };

  return user ? (
    <div>
      <div className="general-data">
        <section className="general-data__personal-info" id="personal-data">
          <h3 className="general-data__title">Datos personales</h3>
          <form className="general-data__personal-info-form">
            <div className="general-data__personal-info-form-izq">
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="name"
                >
                  Nombre*
                </label>
                <InputText
                  error={isNameEntered}
                  onFocus={handleInputNameFocus}
                  onBlur={handleInputNameBlur}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Escribe tu nombre"
                  value={firstName ? firstName : ""}
                  onChange={(event) => {
                    setFirstName(event.target.value
                                  .replace(/\d+/g, '') // Remove numbers
                    );
                  }}
                  required
                />
              </div>
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="nie"
                >
                  NIF / NIE*
                </label>
                <InputText
                  error={isNifEntered}
                  onFocus={handleInputNifFocus}
                  onBlur={handleInputNifBlur}
                  type="text"
                  id="nie"
                  name="nie"
                  placeholder="Escribe tu NIF o NIE"
                  value={nif ? nif : ""}
                  onChange={(event) => {
                    setNif(event.target.value
                      .replaceAll('-', '')
                      .replaceAll('.', ''));
                  }}
                  maxLength={11}
                  required
                />
                
                {Object.keys(error).length && Object.keys(error).includes('nif') ? (
                  <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error.nif}</p>
                ) : null}
              </div>
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="address"
                >
                  Dirección*
                </label>
                <InputText
                  error={isAddressEntered}
                  onFocus={handleInputAddressFocus}
                  onBlur={handleInputAddressBlur}
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Escribe tu dirección"
                  value={address ? address : ""}
                  onChange={(event) => {
                    setAddress(event.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="general-data__personal-info-form-dcha">
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="surname"
                >
                  Apellidos*
                </label>
                <InputText
                  error={isLastNameEntered}
                  onFocus={handleInputLastNameFocus}
                  onBlur={handleInputLastNameBlur}
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Escribe tus apellidos"
                  value={lastName ? lastName : ""}
                  onChange={(event) => {
                    setLastName(event.target.value
                      .replace(/\d+/g, '') // Remove numbers
                    );
                  }}
                  required
                />
              </div>
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="phone"
                >
                  Teléfono*
                </label>
                <InputText
                  error={isPhoneEntered}
                  onFocus={handleInputPhoneFocus}
                  onBlur={handleInputPhoneBlur}
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Escribe tu teléfono"
                  value={phone ? phone : ""}
                  onChange={(event) => {
                    setPhone(event.target.value
                              .replace(/[^0-9]/g, '') // Remove letters
                    );
                  }}
                  maxLength={12}
                  required
                />
              </div>
              <div>
                <label
                  className="general-data__personal-info-form-label"
                  htmlFor="postal-code"
                >
                  Código postal*
                </label>
                <InputText
                  error={isPostalcodeEntered}
                  onFocus={handleInputPostalcodeFocus}
                  onBlur={handleInputPostalcodeBlur}
                  type="text"
                  id="postal-code"
                  name="postal-code"
                  placeholder="Escribe tu código postal"
                  value={postalcode ? postalcode : ""}
                  onChange={(event) => {
                    setPostalcode(event.target.value);
                  }}
                  maxLength={5}
                  required
                />
              </div>
            </div>
          </form>
        </section>

        <section className="general-data__payment">
          <h3 className="general-data__title">Método de pago</h3>
          <div className="my-4">
            {paymentMethods?.length ? (
              <React.Fragment>
                {paymentMethods.map((pm) => (
                  <div className="row align-items-center my-2" key={pm.id}>
                    <div className="col-3">
                      <Icon
                        name={pm.card.brand}
                        size="xlarge"
                        color="secondary-1"
                      />
                    </div>
                    <div className="col-7">
                      <p className="m-0">**** **** **** {pm.card.last4}</p>
                      <p className="m-0">
                        Caduca en {pm.card.exp_month}/{pm.card.exp_year}
                      </p>
                    </div>
                    <div className="col-2">
                      <Icon
                        name="deleteIcon"
                        size="medium"
                        color="secondary-1"
                        className="pointer"
                        onClick={() => {
                          deleteCreditCard(pm.id);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <p className="general-data__payment-not-payment">
                No tienes método de pago.
              </p>
            )}
          </div>

          {addCreditCardForm ? (
            <React.Fragment>
              <div className="mt-4 mb-3">
                <Icon
                  name="close"
                  size="medium"
                  className="float-end mb-3 pointer"
                  color="secondary-1"
                  onClick={() => {
                    addCreditCard();
                  }}
                />

                <Elements stripe={stripe} options={optionsCheckout}>
                  <CheckoutForm
                    updatePaymentMethods={getPaymentMethods}
                    confirmSetupConfig={{ redirect: 'if_required' }}
                  />
                </Elements>
              </div>

              <img src={poweredByStripe} className="mt-1 mx-auto pointer powered-by-stripe" onClick={() => { window.open('https://stripe.com', '_blank').focus() }} />
            </React.Fragment>
          ) : null}

          <div className="general-data__payment-container">
            {addCreditCardForm ? null : (
              <Button
                iconLeft="add"
                text="Añadir un método de pago"
                onClick={() => {
                  addCreditCard();
                }}
              />
            )}
          </div>
        </section>

        <section className="general-data__cash" id="cash">
          <h3 className="general-data__title">Método de cobro</h3>

          {connectedAccount && connectedAccount.external_accounts ?
            <React.Fragment>
              <div className="row">
                <div className="col-md-10"><p></p></div>
                <div className="col-md-2 d-flex align-items-end">
                  <Icon
                    name="deleteIcon"
                    size="medium"
                    color="secondary-1"
                    className="pointer"
                    onClick={() => {
                      setShowDeleteBankAccountModal(true);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 ">
                  <label
                    className="general-data__personal-info-form-label"
                  > Código IBAN*</label>

                  <InputText
                    value={connectedAccount.external_accounts.data.length ? ` ${connectedAccount.country}** **** **** **** **** ${connectedAccount.external_accounts.data[0].last4}` : ''}
                    disabled={true}
                  />
                </div>

                <div className="col-12 col-md-6 d-flex align-items-end">
                  { btnStripeConnectButtonLoading ?
                    <Button
                      className={'btn-white mb-1'}
                      iconLeft="edit"
                      iconRight="loaderIcon"
                      disabled={true}
                      text="Modifica tus datos en Stripe"
                      onClick={() => {}}
                    />
                  :
                    <Button
                      className={'btn-white mb-1'}
                      iconLeft="edit"
                      text="Modifica tus datos en Stripe"
                      onClick={() => {
                        updateConnetedAccount(connectedAccount.id);
                      }}
                    />  
                  }
                  { /*connectedAccount.business_type === 'individual' ?
                    <React.Fragment>
                      <label
                        className="general-data__personal-info-form-label"
                        htmlFor="companyIBAN"
                      > Responsable de la cuenta*</label>

                      <InputText
                        type="text"
                        value={`${connectedAccount.individual.first_name} ${connectedAccount.individual.last_name}`}
                        disabled={true}
                      />
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <label
                        className="general-data__personal-info-form-label"
                      > Empresa*</label>

                      <InputText
                        type="text"
                        value={`${connectedAccount.business_profile.first_name} ${connectedAccount.business_profile.name}`}
                        disabled={true}
                      />
                    </React.Fragment>
                  */}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col">
                  <p>Tipo de cuenta: { connectedAccount.type }</p>
                  <p>Estado de la cuenta: { connectedAccount.payouts_enabled ? <span className="success">Verificada</span> : <span className="red">Pendiente de verificación</span> }</p>
                  <p>Periodicidad transferencia: 
                    { connectedAccount.settings.payouts.schedule.interval === 'monthly' ? ' Mensual' : '' }
                    { connectedAccount.settings.payouts.schedule.interval === 'daily' ? ' Diaria' : '' }
                    { connectedAccount.settings.payouts.schedule.interval === 'weekly' ? ' Semanal' : '' }
                    { connectedAccount.settings.payouts.schedule.interval === 'manual' ? ' Manual' : '' }
                  </p>
                  <p className="general-data__comments mt-4">Recuerda que Bookólicos es una plataforma que da servicio en el mercado español, por lo que nuestros autores deben disponer de un documento de identidad de dicha nacionalidad para poder publicar.</p>
                </div>
              </div>
            </React.Fragment>
            :
            <React.Fragment>
              {addBankAccountForm ?
                <div className="row">
                  <div className="col">
                    <Icon
                      name="close"
                      size="medium"
                      className="float-end mb-3 pointer"
                      color="secondary-1"
                      onClick={() => {
                        addBankAccount();
                      }}
                    />
                  </div>
                </div>
                : null
              }

              <div className="general-data__cash-container">
                {addBankAccountForm ?
                  <React.Fragment>
                    <div className="row mb-4">
                      <div className="col">
                        {/*<label
                          className="general-data__personal-info-form-label mb-3"
                          htmlFor="companyType"
                        > Tipo de empresa*</label>*/}
                        <div className="d-flex">
                          <label className="searcher__filter-item">
                            <input
                              type="radio"
                              name="filter"
                              value="autonomo"
                              checked={companyType === 'autonomo'}
                              onChange={(event) => {
                                setCompanyType(event.target.value);
                              }}
                            />
                            Autor
                          </label>

                          <label className="searcher__filter-item">
                            <input
                              type="radio"
                              name="filter"
                              value="empresa"
                              checked={companyType === 'empresa'}
                              onChange={(event) => {
                                setCompanyType(event.target.value);
                              }}
                            />
                            Empresa
                          </label>
                        </div>
                      </div>

                      <div className="col">
                        <Select
                          id="country"
                          selectedValue={country}
                          defaultValue={'Selecciona el país'}
                          options={{
                            ES: 'España',
                            AL: 'Albania',
                            AU: 'Australia',
                            DE: 'Alemania',
                            AT: 'Austria',
                            BE: 'Bélgica',
                            BY: 'Bielorusia',
                            BO: 'Bolivia',
                            BR: 'Brasil',
                            BG: 'Bulgaria',
                            CA: 'Canada',
                            CL: 'Chile',
                            CN: 'China',
                            CY: 'Chipre',
                            CO: 'Colombia',
                            CR: 'Costa Rica',
                            HR: 'Croacia',
                            CU: 'Cuba',
                            DK: 'Dinamarca',
                            EC: 'Ecuador',
                            SI: 'Eslovenia',
                            SK: 'Eslovaquia',
                            US: 'Estados Unidos',
                            FI: 'Finlandia',
                            FR: 'Francia',
                            GI: 'Gibraltar',
                            GR: 'Grecia',
                            IS: 'Islandia',
                            IE: 'Irlanda',
                            IL: 'Israel',
                            IT: 'Italia',
                            JP: 'Japan',
                            MT: 'Malta',
                            MX: 'Méjico',
                            NI: 'Nicaragua',
                            NO: 'Noruega',
                            NL: 'Paises Bajos',
                            PA: 'Panamá',
                            PY: 'Paraguay',
                            PE: 'Perú',
                            PL: 'Polonia',
                            PT: 'Portugal',
                            PR: 'Puerto Rico',
                            UK: 'Reino Unido',
                            CZ: 'República Checa',
                            DO: 'República Dominicana',
                            RO: 'Rumania',
                            RU: 'Rusia',
                            RS: 'Serbia',
                            ZA: 'Sudafrica',
                            CH: 'Suiza',
                            TR: 'Turquia',
                            UA: 'Ucrania',
                            UY: 'Uruguay',
                            VE: 'Venezuela'
                          }}
                          onSelect={(event) => {
                            setCountry(event.target.value);
                          }}
                          onChange={(event) => {
                            setCountry(event.target.value);
                          }}>

                        </Select>

                        {stripeErrors && stripeErrors.country !== '' ?
                          <p className="error">{stripeErrors.country}</p>
                          : null}

                      </div>
                    </div>

                    {companyType === 'autonomo' ?
                      <React.Fragment>
                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyFirstName"
                            > Nombre*</label>

                            <InputText
                              error={isCompanyFirstNameEntered}
                              onFocus={handleInputCompanyFirstNameFocus}
                              onBlur={handleInputCompanyFirstNameBlur}
                              type="text"
                              id="companyFirstName"
                              name="companyFirstName"
                              value={companyFirstName}
                              placeholder="Introduce tu nombre"
                              onChange={(event) => {
                                setCompanyFirstName(event.target.value);
                              }}
                            />

                            {stripeErrors && stripeErrors.companyFirstName !== '' ?
                              <p className="error">{stripeErrors.companyFirstName}</p>
                              : null}
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyLastName"
                            > Apellidos*</label>

                            <InputText
                              error={isCompanyLastNameEntered}
                              onFocus={handleInputCompanyLastNameFocus}
                              onBlur={handleInputCompanyLastNameBlur}
                              type="text"
                              id="companyLastName"
                              name="companyLastName"
                              value={companyLastName}
                              placeholder="Introduce tu nombre"
                              onChange={(event) => {
                                setCompanyLastName(event.target.value);
                              }}
                            />

                            {stripeErrors && stripeErrors.companyLastName !== '' ?
                              <p className="error">{stripeErrors.companyLastName}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyEmail"
                            > Email*</label>

                            <InputText
                              error={isCompanyEmailEntered}
                              onFocus={handleInputCompanyEmailFocus}
                              onBlur={handleInputCompanyEmailBlur}
                              type="text"
                              id="companyEmail"
                              name="companyEmail"
                              value={companyEmail}
                              placeholder="Introduce el email"
                              onChange={(event) => {
                                setCompanyEmail(event.target.value);
                              }}
                            />

                            {stripeErrors && stripeErrors.companyEmail !== '' ?
                              <p className="error">{stripeErrors.companyEmail}</p>
                              : null}
                          </div>

                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyPhone"
                            > Teléfono*</label>

                            <InputText
                              error={isCompanyPhoneEntered}
                              onFocus={handleInputCompanyPhoneFocus}
                              onBlur={handleInputCompanyPhoneBlur}
                              type="text"
                              id="companyPhone"
                              name="companyPhone"
                              value={companyPhone}
                              placeholder="Introduce el teléfono"
                              onChange={(event) => {
                                setCompanyPhone(event.target.value);
                              }}
                            />

                            {stripeErrors && stripeErrors.companyPhone !== '' ?
                              <p className="error">{stripeErrors.companyPhone}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyFirstName"
                            > Fecha Nacimiento*</label>

                            <div className="row">
                              <div className="col-4">
                                <InputText
                                  error={isCompanyDayBirthEntered}
                                  onFocus={handleInputCompanyDayBirthFocus}
                                  onBlur={handleInputCompanyDayBirthBlur}
                                  type="number"
                                  id="companyDayBirth"
                                  name="companyDayBirth"
                                  value={companyDayBirth}
                                  placeholder="DD"
                                  onChange={(event) => {
                                    setCompanyDayBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyDayBirth !== '' ?
                                  <p className="error">{stripeErrors.companyDayBirth}</p>
                                  : null}
                              </div>
                              <div className="col-4">
                                <InputText
                                  error={isCompanyMonthBirthEntered}
                                  onFocus={handleInputCompanyMonthBirthFocus}
                                  onBlur={handleInputCompanyMonthBirthBlur}
                                  type="number"
                                  id="companyMonthBirth"
                                  name="companyMonthBirth"
                                  value={companyMonthBirth}
                                  placeholder="MM"
                                  onChange={(event) => {
                                    setCompanyMonthBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyMonthBirth !== '' ?
                                  <p className="error">{stripeErrors.companyMonthBirth}</p>
                                  : null}
                              </div>
                              <div className="col-4">
                                <InputText
                                  error={isCompanyYearBirthEntered}
                                  onFocus={handleInputCompanyYearBirthFocus}
                                  onBlur={handleInputCompanyYearBirthBlur}
                                  type="number"
                                  id="companyYearBirth"
                                  name="companyYearBirth"
                                  value={companyYearBirth}
                                  placeholder="YYYY"
                                  onChange={(event) => {
                                    setCompanyYearBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyYearBirth !== '' ?
                                  <p className="error">{stripeErrors.companyYearBirth}</p>
                                  : null}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyCIF"
                            > NIF/CIF*</label>

                            <InputText
                              error={isCompanyCIFEntered}
                              onFocus={handleInputCompanyCIFFocus}
                              onBlur={handleInputCompanyCIFBlur}
                              type="text"
                              id="companyCIF"
                              name="companyCIF"
                              value={companyCIF}
                              placeholder="Introduce tu NIF o CIF"
                              onChange={(event) => {
                                setCompanyCIF(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyCIF !== '' ?
                              <p className="error">{stripeErrors.companyCIF}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyAddress"
                            > Dirección*</label>

                            <InputText
                              error={isCompanyAddressEntered}
                              onFocus={handleInputCompanyAddressFocus}
                              onBlur={handleInputCompanyAddressBlur}
                              type="text"
                              id="companyAddress"
                              name="companyAddress"
                              value={companyAddress}
                              placeholder="Introduce la dirección"
                              onChange={(event) => {
                                setCompanyAddress(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyAddress !== '' ?
                              <p className="error">{stripeErrors.companyAddress}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyZipcode"
                            > Código postal*</label>

                            <InputText
                              error={isCompanyZipcodeEntered}
                              onFocus={handleInputCompanyZipcodeFocus}
                              onBlur={handleInputCompanyZipcodeBlur}
                              type="text"
                              id="companyZipcode"
                              name="companyZipcode"
                              value={companyZipcode}
                              placeholder="Introduce el código postal"
                              onChange={(event) => {
                                setCompanyZipcode(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyZipcode !== '' ?
                              <p className="error">{stripeErrors.companyZipcode}</p>
                              : null}
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyCity"
                            > Ciudad*</label>

                            <InputText
                              error={isCompanyCityEntered}
                              onFocus={handleInputCompanyCityFocus}
                              onBlur={handleInputCompanyCityBlur}
                              type="text"
                              id="companyCity"
                              name="companyCity"
                              value={companyCity}
                              placeholder="Introduce la ciudad"
                              onChange={(event) => {
                                setCompanyCity(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyCity !== '' ?
                              <p className="error">{stripeErrors.companyCity}</p>
                              : null}
                          </div>
                        </div>
                      </React.Fragment>
                      : null}

                    {companyType === 'empresa' ?
                      <React.Fragment>
                        <div className="row">
                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyName"
                            > Nombre de la empresa*</label>

                            <InputText
                              error={isCompanyNameEntered}
                              onFocus={handleInputCompanyNameFocus}
                              onBlur={handleInputCompanyNameBlur}
                              type="text"
                              id="companyName"
                              name="companyName"
                              value={companyName}
                              placeholder="Introduce el nombre de la empresa"
                              onChange={(event) => {
                                setCompanyName(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyName !== '' ?
                              <p className="error">{stripeErrors.companyName}</p>
                              : null}
                          </div>

                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyCIF"
                            > CIF de la empresa*</label>

                            <InputText
                              error={isCompanyCIFEntered}
                              onFocus={handleInputCompanyCIFFocus}
                              onBlur={handleInputCompanyCIFBlur}
                              type="text"
                              id="companyCIF"
                              name="companyCIF"
                              value={companyCIF}
                              placeholder="Introduce el CIF de la empresa"
                              onChange={(event) => {
                                setCompanyCIF(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyCIF !== '' ?
                              <p className="error">{stripeErrors.companyCIF}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyAddress"
                            > Dirección*</label>

                            <InputText
                              error={isCompanyAddressEntered}
                              onFocus={handleInputCompanyAddressFocus}
                              onBlur={handleInputCompanyAddressBlur}
                              type="text"
                              id="companyAddress"
                              name="companyAddress"
                              value={companyAddress}
                              placeholder="Introduce la dirección de la empresa"
                              onChange={(event) => {
                                setCompanyAddress(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyAddress !== '' ?
                              <p className="error">{stripeErrors.companyAddress}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyZipcode"
                            > Código postal*</label>

                            <InputText
                              error={isCompanyZipcodeEntered}
                              onFocus={handleInputCompanyZipcodeFocus}
                              onBlur={handleInputCompanyZipcodeBlur}
                              type="text"
                              id="companyZipcode"
                              name="companyZipcode"
                              value={companyZipcode}
                              placeholder="Introduce el código postal"
                              onChange={(event) => {
                                setCompanyZipcode(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyZipcode !== '' ?
                              <p className="error">{stripeErrors.companyZipcode}</p>
                              : null}
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyCity"
                            > Ciudad*</label>

                            <InputText
                              error={isCompanyCityEntered}
                              onFocus={handleInputCompanyCityFocus}
                              onBlur={handleInputCompanyCityBlur}
                              type="text"
                              id="companyCity"
                              name="companyCity"
                              value={companyCity}
                              placeholder="Introduce la ciudad"
                              onChange={(event) => {
                                setCompanyCity(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyCity !== '' ?
                              <p className="error">{stripeErrors.companyCity}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyEmail"
                            > Email*</label>

                            <InputText
                              error={isCompanyEmailEntered}
                              onFocus={handleInputCompanyEmailFocus}
                              onBlur={handleInputCompanyEmailBlur}
                              type="text"
                              id="companyEmail"
                              name="companyEmail"
                              value={companyEmail}
                              placeholder="Introduce el email"
                              onChange={(event) => {
                                setCompanyEmail(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyEmail !== '' ?
                              <p className="error">{stripeErrors.companyEmail}</p>
                              : null}
                          </div>

                          <div className="col-6">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyPhone"
                            > Teléfono*</label>

                            <InputText
                              error={isCompanyPhoneEntered}
                              onFocus={handleInputCompanyPhoneFocus}
                              onBlur={handleInputCompanyPhoneBlur}
                              type="text"
                              id="companyPhone"
                              name="companyPhone"
                              value={companyPhone}
                              placeholder="Introduce el teléfono"
                              onChange={(event) => {
                                setCompanyPhone(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyPhone !== '' ?
                              <p className="error">{stripeErrors.companyPhone}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyFirstName"
                            > Nombre del responsable*</label>

                            <InputText
                              error={isCompanyFirstNameEntered}
                              onFocus={handleInputCompanyFirstNameFocus}
                              onBlur={handleInputCompanyFirstNameBlur}
                              type="text"
                              id="companyFirstName"
                              name="companyFirstName"
                              value={companyFirstName}
                              placeholder="Introduce el nombre del responsable"
                              onChange={(event) => {
                                setCompanyFirstName(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyFirstName !== '' ?
                              <p className="error">{stripeErrors.companyFirstName}</p>
                              : null}
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyLastName"
                            > Apellidos del responsable*</label>

                            <InputText
                              error={isCompanyLastNameEntered}
                              onFocus={handleInputCompanyLastNameFocus}
                              onBlur={handleInputCompanyLastNameBlur}
                              type="text"
                              id="companyLastName"
                              name="companyLastName"
                              value={companyLastName}
                              placeholder="Introduce los apellidos del responsable"
                              onChange={(event) => {
                                setCompanyLastName(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyLastName !== '' ?
                              <p className="error">{stripeErrors.companyLastName}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyFirstName"
                            > Fecha Nacimiento del responsable*</label>

                            <div className="row">
                              <div className="col-4">
                                <InputText
                                  error={isCompanyDayBirthEntered}
                                  onFocus={handleInputCompanyDayBirthFocus}
                                  onBlur={handleInputCompanyDayBirthBlur}
                                  type="number"
                                  id="companyDayBirth"
                                  name="companyDayBirth"
                                  value={companyDayBirth}
                                  placeholder="DD"
                                  onChange={(event) => {
                                    setCompanyDayBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyDayBirth !== '' ?
                                  <p className="error">{stripeErrors.companyDayBirth}</p>
                                  : null}
                              </div>
                              <div className="col-4">
                                <InputText
                                  error={isCompanyMonthBirthEntered}
                                  onFocus={handleInputCompanyMonthBirthFocus}
                                  onBlur={handleInputCompanyMonthBirthBlur}
                                  type="number"
                                  id="companyMonthBirth"
                                  name="companyMonthBirth"
                                  value={companyMonthBirth}
                                  placeholder="MM"
                                  onChange={(event) => {
                                    setCompanyMonthBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyMonthBirth !== '' ?
                                  <p className="error">{stripeErrors.companyMonthBirth}</p>
                                  : null}
                              </div>
                              <div className="col-4">
                                <InputText
                                  error={isCompanyYearBirthEntered}
                                  onFocus={handleInputCompanyYearBirthFocus}
                                  onBlur={handleInputCompanyYearBirthBlur}
                                  type="number"
                                  id="companyYearBirth"
                                  name="companyYearBirth"
                                  value={companyYearBirth}
                                  placeholder="YYYY"
                                  onChange={(event) => {
                                    setCompanyYearBirth(event.target.value);
                                  }}
                                />
                                {stripeErrors && stripeErrors.companyYearBirth !== '' ?
                                  <p className="error">{stripeErrors.companyYearBirth}</p>
                                  : null}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyRelationship"
                            > Relación con la empresa*</label>

                            <InputText
                              error={isCompanyRelationshipEntered}
                              onFocus={handleInputCompanyRelationshipFocus}
                              onBlur={handleInputCompanyRelationshipBlur}
                              type="text"
                              id="companyRelationship"
                              name="companyRelationship"
                              value={companyRelationship}
                              placeholder="Introduce el cargo dentro de la empresa"
                              onChange={(event) => {
                                setCompanyRelationship(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyRelationship !== '' ?
                              <p className="error">{stripeErrors.companyRelationship}</p>
                              : null}
                          </div>
                        </div>
                      </React.Fragment>
                      : null}

                    {companyType === 'empresa' || companyType === 'autonomo' ?
                      <React.Fragment>
                        <div className="row">
                          <div className="col">
                            <label
                              className="general-data__personal-info-form-label"
                              htmlFor="companyIBAN"
                            > Código IBAN*</label>

                            <InputText
                              error={isCompanyIBANEntered}
                              onFocus={handleInputCompanyIBANFocus}
                              onBlur={handleInputCompanyIBANBlur}
                              type="text"
                              id="companyIBAN"
                              name="companyIBAN"
                              value={companyIBAN}
                              placeholder="Introduce el código IBAN de la cuenta donde quieres recibir el dinero"
                              onChange={(event) => {
                                setCompanyIBAN(event.target.value);
                              }}
                            />
                            {stripeErrors && stripeErrors.companyIBAN !== '' ?
                              <p className="error">{stripeErrors.companyIBAN}</p>
                              : null}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col">
                            <CheckBox
                              id="stripeTOS"
                              name="stripeTOS"
                              onChange={handleTOSChange}
                              checked={stripeTOS === true ? true : false}
                              label={<span>Acepto los <span className="link-terms" onClick={() => { setShowStripeTOS(showStripeTOS ? false : true) }}>términos y condiciones</span> de Stripe</span>}
                            />
                            {stripeErrors && stripeErrors.StripeTOS !== '' ?
                              <p className="error mt-3">{stripeErrors.StripeTOS}</p>
                              : null}

                            {showStripeTOS ?
                              <div className="stripeTos my-3">
                                Los servicios de procesamiento de pago para el autor en Bookólicos son proporcionados por Stripe y están sujetos al Acuerdo de cuentas conectadas de Stripe,
                                que incluye los Términos de servicio de Stripe, en conjunto, el <a href="https://assets.ctfassets.net/fzn2n1nzq965/5OrUN3F6HKqsz2OD5IzSP1/6af79d05f5cde0976e5b7ad04af3c4cf/-en-ES-_Stripe_Services_Agreement_-_November_17__2022.pdf" target="_blank">Acuerdo de servicio de Stripe</a>.
                                Al aceptarlos o seguir operando como autor en Bookólicos, usted acepta cumplir las obligaciones del Acuerdo de servicios de Stripe, que puede ser modificado por Stripe de tanto en tanto.
                                Para que Bookólicos pueda ofrecer servicios de procesamiento de pagos a través de Stripe, usted acepta proporcionar a Bookólicos información completa y exacta sobre usted y su negocio, y autoriza a Bookólicos a compartir dicha información
                                y los datos de las transacciones relacionadas con el uso de los servicios de procesamiento de pago proporcionados por Stripe.
                              </div>
                              : null}
                          </div>
                        </div>

                        {stripeErrors && stripeErrors.stripeAPI !== '' ?
                          <p className="error text-center">{stripeErrors.stripeAPI}</p>
                          : null}

                        <Button
                          text={'Guardar'}
                          className={'btn-white m-auto mt-4'}
                          onClick={() => saveBankAccount()}
                        />

                        <div className="row">
                          <div className="col text-center">
                            <img src={poweredByStripe} className="mt-4 mx-auto pointer powered-by-stripe" onClick={() => { window.open('https://stripe.com', '_blank').focus() }} />
                          </div>
                        </div>

                      </React.Fragment>
                      : null}
                  </React.Fragment>

                  : null}

                {!addBankAccountForm ?
                  <React.Fragment>
                    { btnStripeConnectButtonLoading ?
                      <Button
                        className={'btn-white mt-5'}
                        iconLeft="add"
                        iconRight="loaderIcon"
                        disabled={true}
                        text="Conecta tu cuenta con Stripe"
                        onClick={() => {}}
                      />
                    :
                      <Button
                        className={'btn-white mt-5'}
                        iconLeft="add"
                        text="Conecta tu cuenta con Stripe"
                        onClick={() => {
                          createConnetedAccount();
                        }}
                      />  
                    }
                  </React.Fragment>

                  /*<Button
                    className={'btn-white mt-5'}
                    iconLeft="add"
                    text="Añadir un método de cobro"
                    onClick={() => {
                      addBankAccount();
                    }}
                  />
*/                : null }

                <div>
                  <ReactPlayer
                    className='react-player mt-4'
                    url= 'http://blog.bookolicos.com/wp-content/uploads/2024/04/bookolicos_guia_stripe_2024.mp4'
                    width='100%'
                    height='auto'
                    style={{'cursor':'pointer'}}
                    controls = {true}
                    config={{ file: { 
                      attributes: {
                        controlsList: 'nodownload',
                        poster: '/images/bookolicos_guia_poster.jpg'
                      }
                    }}}
                  />
                </div>
                
                <p className="general-data__comments mt-4">Estos datos son necesarios en el caso de que subas una obra a Bookólicos. Al recibir una venta, tus ganancias serán enviadas directamente a esta cuenta.</p>
                <p className="general-data__comments mt-4">Recuerda que Bookólicos es una plataforma que da servicio en el mercado español, por lo que nuestros autores deben disponer de un documento de identidad de dicha nacionalidad para poder publicar.</p>

              </div>
            </React.Fragment>
          }
        </section>
      </div>

      {errorMessage && errorMessage !== "" ? (
        <p>{errorMessage.toString()}</p>
      ) : null}

      <section className="buttons">
        <div className="buttons__containers">
          {user.firstName === "" ||
            user.lastName === "" ||
            user.nif === "" ||
            user.phone === "" ||
            user.address === "" ||
            user.postalCode === "" ? (
            <Button
              className="btn-graybg big"
              text={"Guardar cambios"}
              onClick={() => {}}
            />
          ) : (
            <Button
              className="btn-orangebg big"
              iconRight={saveDataIcon}
              text={"Guardar cambios"}
              disabled={saveDataButtonDisabled}
              onClick={() => {
                saveData();
              }}
            />
          )}
        </div>
      </section>

      <ModalMessage
        header={"¿Estas seguro de que quieres eliminar este método de cobro?"}
        okButton={true}
        okButtonText={"Eliminar"}
        cancelButton={true}
        cancelButtonText={"Cancelar"}
        isConfirmModal={true}
        show={showDeleteBankAccountModal}
        onAccept={deleteBankAccount}
        onCancel={() => setShowDeleteBankAccountModal(false)}
      />

      <ModalMessage
        header="No te olvides de valorar tu experiencia literaria"
        okButton
        okButtonText={"Aceptar"}
        isConfirmModal
        show={showLikeReminderModal}
        onAccept={closeLikeReminderModal}
      />

      <ModalMessage
        okButton={true}
        okButtonText={"Eliminar"}
        cancelButton={true}
        cancelButtonText={"Cancelar"}
        isConfirmModal={true}
        show={showDeletePaymentMethodModal}
      />
    </div>
  ) : null;
}

export default GeneralData;
